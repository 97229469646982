var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: "mdi-book-open-page-variant", title: "Folhas de Ponto" },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              _c("s-icon-report-download-optimized", {
                attrs: { "show-message": "", report: "96" }
              }),
              _c("s-icon-report-download-optimized", {
                attrs: {
                  "show-message": "",
                  report: "97",
                  color: "purple",
                  "tool-tip": "Relatório Resumido"
                }
              }),
              _c("emc-button-icon", {
                attrs: {
                  icon: "mdi-account-multiple-plus-outline",
                  text: "Folha de Ponto em Lote",
                  color: "primary"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    _vm.showFormAll = true
                  }
                }
              }),
              _c("emc-button-icon", {
                attrs: {
                  icon: "mdi-plus",
                  text: "Novo Cadastro",
                  color: "primary"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    _vm.showForm = true
                  }
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-loader-progress", { attrs: { show: _vm.loadingProgress } }),
      _c("emc-table-items", {
        attrs: {
          headers: _vm.headers,
          params: _vm.params,
          collections: _vm.collections,
          meta: _vm.meta,
          itemDelete: _vm.itemDelete,
          itemEdit: _vm.model,
          loading: _vm.loading
        },
        on: {
          "update:params": function($event) {
            _vm.params = $event
          },
          "update:collections": function($event) {
            _vm.collections = $event
          },
          "update:meta": function($event) {
            _vm.meta = $event
          },
          "update:itemDelete": function($event) {
            _vm.itemDelete = $event
          },
          "update:item-delete": function($event) {
            _vm.itemDelete = $event
          },
          "update:itemEdit": function($event) {
            _vm.model = $event
          },
          "update:item-edit": function($event) {
            _vm.model = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("emc-table-icon-edit", {
                  attrs: { color: "success", icon: "mdi-calendar-account" },
                  on: {
                    click: function($event) {
                      return _vm.getSheetItems(item)
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("sys-occurrence-sheet-register", {
        attrs: { model: _vm.model, show: _vm.showForm },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          "update:show": function($event) {
            _vm.showForm = $event
          }
        }
      }),
      _c("sys-occurrence-sheet-all-register", {
        attrs: { show: _vm.showFormAll },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          "update:show": function($event) {
            _vm.showFormAll = $event
          }
        }
      }),
      _c("sys-occurrence-sheet-item-register", {
        attrs: { model: _vm.modelItems, show: _vm.showFormItems },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          "update:show": function($event) {
            _vm.showFormItems = $event
          }
        }
      }),
      _c("emc-alert-modal", {
        attrs: { text: _vm.textDelete, disabled: _vm.deleting, show: _vm.show },
        on: {
          "update:show": function($event) {
            _vm.show = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.deleting,
                      disabled: _vm.deleting
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteItem()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-snackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          color: _vm.error ? "error" : "success"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }